import { Analytics, AnalyticsBrowser } from "@june-so/analytics-next"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { OG_DOMAIN } from "~/consts/users"
import { GetUserPermissions } from "~/routes/__protected/api/user/permissions"
import { useEnv } from "./useEnv"
import { useUserPermissions } from "./useUserPermissions"

const useJuneAnalyticsSetup = () => {
  // Create a state container for the analytics instance.
  const [analytics, setAnalytics] = useState<Analytics>()
  const env = useEnv()
  const userPermissions = useUserPermissions()

  // On component mount, create an AnalyticsBrowser instance.
  useEffect(() => {
    const loadAnalytics = async () => {
      console.log("June Analytics being initialized...")

      if (!env?.JUNE_WRITE_KEY) return

      const [response] = await AnalyticsBrowser.load({
        writeKey: env.JUNE_WRITE_KEY,
      })

      console.log("June Analytics loaded!")
      setAnalytics(response)
    }

    loadAnalytics()
  }, [env?.JUNE_WRITE_KEY])

  useEffect(() => {
    // Send a pageview event once Analytics is ready (expect for OG users).

    if (
      analytics &&
      userPermissions.data &&
      !userPermissions.data.email?.includes(OG_DOMAIN)
    ) {
      analytics.page()
    }
  }, [analytics, userPermissions.data])

  return analytics
}

const context = createContext<Analytics | undefined>(undefined)

interface Props {
  children: ReactNode
}

export const JuneAnalyticsProvider = ({ children }: Props) => {
  const analytics = useJuneAnalyticsSetup()

  return <context.Provider value={analytics}>{children}</context.Provider>
}

export const useJuneAnalytics = () => useContext(context)

export const identifyJuneAnalytics = async (
  analytics: Analytics,
  userPermissions: GetUserPermissions
) => {
  // * Skip OG users
  if (userPermissions?.email?.includes(OG_DOMAIN)) return

  console.time("june-setup")
  console.timeLog("june-setup", "IDENTIFY")

  await analytics.identify(userPermissions?.userId, {
    email: userPermissions?.email,
    firstName: userPermissions?.first_name,
    lastName: userPermissions?.last_name,
    isAdmin: userPermissions?.isAdmin,
    companyAccess: userPermissions?.organization?.company
      ? "premium"
      : userPermissions?.organization?.companyTrial
      ? "trial"
      : "none",
    talentAccess: userPermissions?.organization?.talent
      ? "premium"
      : userPermissions?.organization?.talentTrial
      ? "trial"
      : "none",
    stratintelAccess: userPermissions?.organization?.stratintel
      ? "premium"
      : userPermissions?.organization?.stratintelTrial
      ? "trial"
      : "none",
  })

  console.timeLog("june-setup", "GROUP")

  await analytics.group(userPermissions?.organizationId, {
    name: userPermissions?.organization?.name,
    orgId: userPermissions?.organization?.tag,
    domainPrimary: userPermissions?.organization?.domainPrimary,
    domainOthers: userPermissions?.organization?.domainOthers,
  })

  console.timeEnd("june-setup")
}
